.banner-main img {
    width: 100%;
    position: relative;
}

.banner-main {
    background-image: url(/public/Banner.jpg);
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.banner-txt {
    color: #fff;
}

.textdec {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-allignment {
    justify-content: center;
    
}

.banner-txt h1 {
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
}

.row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}

.main-heading,
.main-heading2 {
    color: #EC1263;
}

.bannerTwo {
    background-color: #FFF9FA;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.Mainbanner2 {

    background-color: #FFF9FA;

}

.banner2 {
    display: flex;
    justify-content: center;
    text-align: center;
}

.main-heading2 {
    font-size: 60px;
    font-weight: 700;
}

.main-heading3 {
    font-size: 50px;
    font-weight: 400;
    text-transform: uppercase;
}

.banner-txt2 {
    font-size: 16px;
    font-weight: 400;
}

.banner-txt3 img {
    width: 100%;
}

.bannerthree {
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.main_title .tit1 {
    font-size: 60px;
    font-weight: 700;
    color: #EC1263
}

/* Overview Section */

.overviewBanner {
    background-color: #FFF9FA;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.overview-card-1 {
    margin-top: 1rem;
    background-color: #fff;
    width: 100%;
    box-shadow: 0px 0px 50px rgba(239, 47, 96, 0.2);
    border-radius: 20px;
    padding: 50px 100px;
    justify-content: center;
    text-align: center
}

.overview-icon img {
    width: 30%;
}

.overviewBanner .row {
    --bs-gutter-x: 1.5rem !important;
}

.overview-row {
    text-align: center;
}

.overview-main-title h1 {
    font-size: 56px;
    font-weight: 700;
}

.overview-title h2 {
    font-weight: 700;
    padding-top: 30px;
}

/* KeyFeatures */

.keyfeaturesbanner {
    background-color: #ffffff;
    padding-top: 10rem;
    padding-bottom: 10rem;

}

.keycard {
    background-color: #fff;
    box-shadow: 0px 0px 50px rgba(239, 47, 96, 0.2);
    border-radius: 20px;
    padding: 30px 30px;
    text-align: center;
    height: 35rem;
    overflow: hidden;
}

.keycard:hover {
    background-color: #EC1263;
    color: #fff;
    cursor: pointer;
}

.cardtitle {
    font-size: 30px;
    font-weight: 700;
    padding: 20px 50px;
    line-height: 37px;
}

.card-para {
    font-size: 18px;
    padding: 14px;
    font-weight: 400;
}

.keyfeatures-col-card {
    text-align: center;
    padding-bottom: 70px;
    font-weight: 800;
    font-size: 72px;
}

.keyfeatures-col {
    justify-content: center;
    display: flex;
    padding-bottom: 5rem;
}

.mainttle-c {
    font-weight: 700;
    font-size: 60px;
    color: #EC1263;
}

.mainttle-c1 {
    font-size: 60px;
}

.keyfeatures-row-card {
    --bs-gutter-x: 1.5rem !important;
    --bs-gutter-y: -40px !important;

}

/* ContactUs Page */
.contactudtitle {
    text-align: center;
}

.contactuspage {
    background-color: #FFF9FA;
    padding-top: 10rem;
    padding-bottom: 10rem;
}

.contactudtitle h1 {
    font-size: 72px;
    font-weight: bold;
    color: #0A093D;
    padding-bottom: 60px;
}

.form-control {
    border: 1px solid transparent !important;
    border-bottom: 1px solid #2181ff25 !important;
    width: 100% !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    height: 50px;
    margin-bottom: 2rem;
    font-family: 'Raleway', sans-serif;

}
.form-control:focus {
    background-color: rgba(255, 255, 255, 0) !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.25rem rgba(13, 109, 253, 0) !important;
}
.contact-freedemo-btn{
    width: 100%;
    height: 50px;
    border-radius: 100px !important;
    font-weight: 700 !important;
    background-color: #EC1263 !important;
    border: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.titile-contact{
    padding: 10px 50px;
}
.titile-contact p{
    font-size: 24px;
}
.titile-contact1{
    padding: 10px 50px;
}
.iconc img{
    width: 40%;
}
.adresstext{
    font-size: 20px;
    line-height: 24px;
}
.adressbar{
    padding-bottom: 30px;
}
.text-allignment img{
    width: 20%;
}
@media (max-width: 991px) {
    .iconc img{
        width: 10%;
    }
    .contactudtitle h1 {
        font-size: 52px;
    }
    .keycard {
        height: auto;
    }
    .keyfeaturesbanner {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .keyfeatures-col {
        padding-bottom: 2rem;
    }
    .keyfeatures-col{
        text-align: center;
    }
    .overviewBanner {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .overview-main-title h1 {
        font-size: 40px;
    }
    .bannerthree {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .main_title .tit1 {
        text-align: center;
    }
    .main_title .tit2 {
        text-align: center;
    }
    .main_title p{
        text-align: center;
    }
    .bannerTwo {
        background-color: #FFF9FA;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .text-allignment img {
        width: 34%;
    }
    .banner-txt h1 {
        font-size: 48px;
        text-align: center;
    }
    .textdec{
        text-align: center;
    }
    .contactuspage {
        background-color: #FFF9FA;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

        .social {
            padding-bottom: 20px;
            justify-content: center
        }
        .navbar-collapse {
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        .navbar-container.container {
            justify-content: center !important;
        }

 }