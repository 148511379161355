.navbar-c{
    background-color: black;
}
a.navbar-brand{
    color: #EC1263;
    font-weight: 700;
    text-transform: uppercase;
}
a.navbar-brand img {
    width: 18%;
}
a.nav-link {
    color: #fff;
    font-weight: 500;
}
a.nav-link:hover{
    color: #EC1263;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #EC1263 !important;
}
.social{
    color: #fff;
    display: flex;
}

.social .facebook{
    padding: 0px 10px;
}
.social .facebook:hover{
    color: #EC1263;
}
.freedemo-btn{
    background-color: #EC1263;
    border: none;
    border-radius: 20px;
    padding: 5px 30px;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-image: url(/public/menu-60.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.navbar-brand:hover {
    color:#EC1263;
}
.navbar-brand:focus, .navbar-brand:hover {
    color: #EC1263!important;
}